function addCustomField(data) {
    var id = data.id,
        name = data.name,
        type = data.type,
        value = data.value,
        required = data.required;

    var html = '<div class="form-group">';
    html += '<label for="' + id + '" class="control-label col-sm-2">' + name + '</label>';
    html += '<div class="col-sm-10">';

    if(type == 'text') {
        html += '<input type="text" name="custom_field[' + id + ']" value="" class="form-control" id="' + id + '" placeholder="' + name + '" />';
    } else if(type == 'multiline') {
        html += '<textarea name="custom_field[' + id + ']" class="form-control" id="' + id + '" placeholder="' + name + '" rows="5"></textarea>';
    } else if(type == 'select') {
        html += '<select name="custom_field[' + id + ']" class="form-control" id="select-' + id + '">';
        html += '<option value="0">--- Please select ---</option>';
        $.each(value, function( index, val ) {
            html += '<option value="' + val + '">' + val + '</option>';
        });
        html += '</select>';
    } else if(type == 'checkbox') {
        html += '<input type="hidden" name="custom_field[' + id + '][]" value="0" checked />';
        $.each(value, function( index, val ) {
            html += '<p><input type="checkbox" name="custom_field[' + id + '][]" value="' + val + '" id="checkbox-' + val + '" />';
            html += '<label class="control-label" for="checkbox-' + val + '">&nbsp;' + val + '</label></p>';
        });
    } else if(type == 'radio') {
        html += '<input type="hidden" name="custom_field[' + id + '][]" value="0" checked />';
        $.each(value, function( index, val ) {
            html += '<p><input type="radio" name="custom_field[' + id + ']" value="' + val + '" id="radio-' + val + '" />';
            html += '<label class="control-label" for="radio-' + val + '">&nbsp;' + val + '</label></p>';
        });
    } else if(type == 'date') {
        html += '<div class="input-group date">';
        html += '<input type="text" name="custom_field[' + id + ']" class="form-control" />';
        html += '<span class="input-group-addon">';
        html += '<span class="glyphicon glyphicon-calendar"></span>';
        html += '</span>';
        html += '</div>';
    }

    html += '</div>';
    html += '</div>';

    return html;
}

function isInt(n){
    return Number(n) === n && n % 1 === 0;
}